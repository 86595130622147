import { Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/login/login.routes').then((mod) => mod.ROUTES),
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.routes').then((mod) => mod.ROUTES),
    canActivate: [AuthGuard],
  },
];
