import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { CustomMessageService } from './core/service/custom-message.service';
import { PrimeNGTranslationService } from './prime-ng-translation.service';
import { HeaderComponent } from './shared/components/header/header.component';
import { PrimengModule } from './shared/primeng/primeng.module';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ToastModule,
    HeaderComponent,
    ConfirmDialogModule,
    HttpClientModule,
    NgxSpinnerModule,
    PrimengModule,
  ],
  providers: [MessageService],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'diamond-cearapar-societaria';
  isLoggedIn = false;

  constructor(
    private translationService: PrimeNGTranslationService,
    private customMessageService: CustomMessageService,
    private router: Router,
    private messageService: MessageService,
  ) {
    this.router.events.subscribe((event) => {
      // Salva o link para redirecionar apos o login
      if (event instanceof NavigationStart) {
        if (event.url?.includes('assembleia-cadastro')) {
          // redirectUrlAfterLogin deve ser salvo globalmente ?
          sessionStorage.setItem('redirectUrlAfterLogin', event.url);
        }
      }
      if (event instanceof NavigationEnd) {
        this.atualizarEstadoLogin(event.url);
      }
    });
    this.customMessageService.messages.subscribe((res) => {
      this.messageService.add(res);
    });
  }

  atualizarEstadoLogin(url: string) {
    this.isLoggedIn = url !== '/' && url !== '/cadastro' && url !== '/cadastro-sucesso';
  }

  ngOnInit(): void {
    this.translationService.setPortugueseTranslation();
  }
}
