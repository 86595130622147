import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserStorageService } from '../service/user-storage.service';

export class AutenticacaoInterceptor implements HttpInterceptor {
  constructor(private userService: UserStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.inserirToken(request));
  }

  private inserirToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.userService.getToken();
    let clone = request;
    if (token && !request.headers.get('Authorization')) {
      clone = request.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }
    return clone;
  }
}
