import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomMessageService } from '../service/custom-message.service';
import { UserStorageService } from '../service/user-storage.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private mensagemService: CustomMessageService,
    private userService: UserStorageService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let mensagemErro = '';
        if (error?.error?.error === 'unauthorized' && error?.error?.error_description === 'E-mail nao cadastrado') {
          mensagemErro = 'E-mail não cadastrado.';
        } else if (
          error?.error?.error === 'unauthorized' &&
          error?.error?.error_description === 'Favor aguarde seu usu&aacute;rio, ser aprovado.'
        ) {
          mensagemErro = 'Favor aguarde seu usuário ser aprovado.';
        } else if (error?.status === 401) {
          this.userService.clean();
          this.router.navigate(['/']);
          mensagemErro = 'Sessão Expirada.';
        } else if (error?.error?.detail === 'Usuário ou senha inválidos!') {
          mensagemErro = error?.error?.detail;
        } else if (error?.error?.mensagem === 'Ja existe comentario cadastrado') {
          mensagemErro = 'Já existe comentário cadastrado';
        } else if (error?.error?.mensagem === 'Não existe comentario') {
          mensagemErro = 'Não existe comentário';
        } else if (error?.error?.mensagem) {
          mensagemErro = error?.error?.mensagem;
        } else {
          mensagemErro = 'Erro desconhecido. Por favor, contate o suporte.';
        }
        this.mensagemService.showError(mensagemErro);
        return throwError(() => mensagemErro);
      }),
    );
  }
}
