import { AppComponent } from '@app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { PrimeNGTranslationService } from '@app/prime-ng-translation.service';
import { CustomMessageService } from '@app/core/service/custom-message.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoadingInterceptor } from '@app/core/interceptor/loading.interceptor';
import { LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from '@app/app-routes.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AutenticacaoInterceptor } from '@app/core/interceptor/autenticacao.interceptor';
import { UserStorageService } from '@app/core/service/user-storage.service';
import { ErrorInterceptor } from '@app/core/interceptor/error.interceptor';
import { AuthGuard } from '@app/core/guard/auth.guard';

registerLocaleData(localePt);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(NgxMaskModule.forRoot()),
    PrimeNGTranslationService,
    ConfirmationService,
    AuthGuard,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AutenticacaoInterceptor,
      multi: true,
      deps: [UserStorageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    provideRouter(appRoutes),
  ],
});
